<template>
      <div class='sector flex-align-center'>
            <div class="breadcrumb">当前位置：课程广场>{{$route.query.name}}</div>
            <div class="content flex-between">
                  <div class="plan flex-column-center">
                        <div class="cover flex-align-center">
                              <span>名家书法经典课（3节）</span>
                        </div>
                        <div class="join flex-align-center">
                              <img src="@/assets/courseSquare/join.png">
                              <span>加入教学计划</span>
                        </div>
                        <div class="subject flex-align-between">
                              <div class="flex-layout">
                                    <img src="@/assets/courseSquare/teaching.png">
                                    <span>教案</span>
                              </div>
                              <div class="flex-layout">
                                    <img src="@/assets/courseSquare/interaction.png">
                                    <span>互动</span>
                              </div>
                              <div class="flex-layout">
                                    <img src="@/assets/courseSquare/video.png">
                                    <span>视频</span>
                              </div>
                              <div class="flex-layout">
                                    <img src="@/assets/courseSquare/appraisal.png">
                                    <span>AI测评</span>
                              </div>

                        </div>
                  </div>
                  <div class="introduce flex-column">
                        <div class="introduce_title flex-align-center">
                              <span :class="{'spanF':introduceStatus == true}" @click="explain">课程简介</span>
                              <span>|</span>
                              <span :class="{'spanF':introduceStatus == false}" @click="catalogue">课程目录</span>
                        </div>
                        <div class="introduce_general explain" v-if="introduceStatus">
                              这套课程包括15个课时，是小学阶段这套课程包括15个课时，是小学阶段这套课程包括15个课时，是小学阶段这套课程包括15个课时，是小学阶段这套课程包括15个课时，是小学阶段这套课程包括15个课时，是小学阶段这套课程包括15个课时，是小学阶段这套课程包括15个课时，是小学阶段这套课程包括15个课时，是小学阶段这套课程包括15个课时，是小学阶段。
                        </div>
                        <div class="introduce_general catalogue" v-else>
                              <div class="daughter" v-for="(item,index) in  Object.keys(catalogueList).sort()"
                                    :key="index">

                                    <div class="title"> {{item}}</div>
                                    <div class="Lessons flex-align-between"
                                          v-for="(itemSon,indexSon) in catalogueList[item]" :key="indexSon">
                                          <span
                                                :style="{'color':itemSon.classStatus?'#21BD99':''}">{{itemSon.className}}</span>
                                          <div>
                                                <img class="play" src="@/assets/courseSquare/play.png"
                                                      v-if="itemSon.classStatus">
                                                <img class="forbid" src="@/assets/courseSquare/forbid.png" v-else
                                                      @click="forbid">
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
</template>

<script>
      export default {
            data() {
                  return {
                        introduceStatus: true,
                        catalogueList: {
                              '第一单元：人物传记': [{
                                          className: '1.《小英雄雨来》阅读指导',
                                          classStatus: true,
                                    },
                                    {
                                          className: '2.《鲁宾孙漂流记》阅读指导',
                                          classStatus: false,
                                    },
                                    {
                                          className: '3.《居里夫人》阅读指导',
                                          classStatus: true,
                                    },

                              ],
                              '第二单元：游记': [{
                                          className: '4.《鲁宾孙漂流记》阅读指导',
                                          classStatus: false,
                                    },
                                    {
                                          className: '5.《格列弗游记》阅读指导',
                                          classStatus: false,
                                    },
                              ]
                        },
                  }
            },
            //方法
            methods: {
                  explain() {
                        this.introduceStatus = true;
                  },
                  catalogue() {
                        this.introduceStatus = false;
                  },
                  forbid() {
                        this.$confirm('本章节暂无权限观看，请联系管理员开通。', '提示', {
                              confirmButtonText: '确定',
                              type: '',
                              center: true,
                              showCancelButton: false,
                              confirmButtonClass: 'el_Forbid'
                        }).then(() => {

                        }).catch(() => {

                        });
                  },
            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {

            },
      }
</script>
<style lang='less' scoped>
      @import './less/sector';
</style>
<style>
      .el_Forbid {
            background-color: #21BD99 !important;
            color: #fff !important;
            border-color: #21BD99 !important;
      }
</style>